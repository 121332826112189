<template>
  <div class="add-avatar">
    <div class="avatar-container">
      <div class="avatar-image">
        <ElAvatar
          class="profile-img"
          :src="fileCopy?.value?.encode_file ?? fileCopy?.encode_file ?? fileCopy?.value?.url ?? fileCopy?.url ?? ''"
        >
          <Icons name="24px/image_3" />
        </ElAvatar>
      </div>
      <div
        class="avatar-uploader"
        v-if="
          width > 1100
            ? fileCopy?.value?.encode_file
              ? false
              : fileCopy?.encode_file
              ? false
              : fileCopy?.value?.url
              ? false
              : fileCopy?.url
              ? false
              : true
            : false
        "
      >
        <el-upload
          accept="file"
          drag
          :show-file-list="false"
          :http-request="() => {}"
          :on-change="onChangePhoto"
        >
          <div class="uploader-icon">
            <Icons name="18px/file_upload_purple" />
          </div>
          <div class="uploader-title">
            <span class="title1 body2-strong"
              >{{ $t('click_to_upload') }}&nbsp;</span
            >
            <span class="title2 body2">{{ $t('or_drag_and_drop') }}</span>
          </div>
          <div class="uploader-subtitle">
            <span class="caption"
              >{{ $t('format_supported') }} ({{
                $t('max_file_size_mb', { size: fileSizeLimit / 1000000 })
              }})</span
            >
          </div>
        </el-upload>
      </div>
      <div
        class="avatar-new-uploader"
        v-if="
          width > 1100
            ? fileCopy?.value?.encode_file
              ? true
              : fileCopy?.encode_file
              ? true
              : fileCopy?.value?.url
              ? true
              : fileCopy?.url
              ? true
              : false
            : false
        "
      >
        <div class="button-remove">
          <ElButton type="delete" @click="removeFile()">
            <span v-if="width > 1350">{{ $t('remove') }}</span>
            <Icons v-else name="24px/trash_red" />
          </ElButton>
        </div>
        <div class="button-upload" @click="$refs.FileInput.click()">
          <ElButton type="secondary">
            <Icons
              name="24px/group_purple"
              :style="width <= 1350 ? 'margin-right: -2px !important' : ''"
            />
            <span v-if="width > 1350">{{ $t('upload_new_picture') }}</span>
          </ElButton>
        </div>
        <input
          ref="FileInput"
          type="file"
          accept="image/*"
          style="display: none"
          @change="uploadImage($event)"
        />
      </div>
      <div class="avatar-new-uploader" v-if="width <= 1100">
        <div class="button-upload">
          <ElButton
            type="link"
            v-if="
              fileCopy?.value?.encode_file
                ? true
                : fileCopy?.encode_file
                ? true
                : fileCopy?.value?.url
                ? true
                : fileCopy?.url
                ? true
                : false
            "
            @click="showChangePictureDialog = true"
          >
            {{ $t('change_picture') }}
          </ElButton>
          <ElButton
            type="link"
            v-if="
              fileCopy?.value?.encode_file
                ? false
                : fileCopy?.encode_file
                ? false
                : fileCopy?.value?.url
                ? false
                : fileCopy?.url
                ? false
                : true
            "
            @click="$refs.FileInput.click()"
          >
            {{ $t('add_picture') }}
          </ElButton>
          <input
            ref="FileInput"
            type="file"
            accept="image/*"
            style="display: none"
            @change="uploadImage($event)"
          />
        </div>
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      v-model="showChangePictureDialog"
      class="custom-delete-box no-subtitle"
      :width="width <= 600 ? '100%' : '70vw'"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('change_picture') }}
        </span>
      </template>
      <ElRow :gutter="20" justify="center" align="middle">
        <ElCol :span="24" :xs="24" :lg="24">
          <ElForm>
            <ElRow :gutter="20">
              <ElCol :span="width <= 700 ? 24 : 12" :lg="12">
                <ElButton
                  style="width: 100%"
                  type="secondary"
                  @click="$refs.FileInput.click()"
                >
                  {{ $t('upload_new_picture') }}
                </ElButton>
              </ElCol>
              <ElCol :span="width <= 700 ? 24 : 12" :lg="12">
                <ElButton
                  :loading="loading"
                  :disabled="loading"
                  type="primary"
                  style="width: 100%"
                  @click="removeFile()"
                >
                  {{ $t('delete_current_picture') }}
                </ElButton>
              </ElCol>
            </ElRow>
          </ElForm>
        </ElCol>
      </ElRow>
    </el-dialog>
  </div>
</template>

<script setup>
import { ElMessageBox } from 'element-plus'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  file: {
    type: Object,
    required: false,
    default: () => {}
  }
})

const emits = defineEmits(['avatarUploaded'])

const { $showError } = useNuxtApp()
const { t } = useI18n()
const nameFile = ref('')
const { width } = useBreakpoints()
const { fileSizeLimit } = getFormInitialVariables()
const originalImage = ref(null)
const fileVariables = reactive({
  file: null
})
const fileList = ref([])
const filesToUpload = ref([])
const loading = ref(false)
const showChangePictureDialog = ref(false)

const fileCopy = ref()

onMounted(async () => {
  if (props.file) {
    fileCopy.value = ref(props.file)
  }
})

const onChangePhoto = (uploadedFile, uploadedFiles) => {
  const file = uploadedFiles[uploadedFiles.length - 1]?.raw
  if (file) {
    uploadImage({ target: { files: [file] } })
  }
}

const uploadImage = (event) => {
  fileCopy.value = {
    encode_file: ''
  }
  const input = event.target
  if (input.files && input.files[0]) {
    if (
      !['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'].includes(
        input.files[0].type
      )
    ) {
      $showError(t('invalid_image'))
    } else if (input.files[0].size > fileSizeLimit) {
      $showError(t('file_size_limit', { size: fileSizeLimit / 1000000 }))
    } else {
      originalImage.value = input.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        loadImage(e.target.result)
      }
      reader.readAsDataURL(input.files[0])
      nameFile.value = input.files[0].name
    }
  } else {
    $showError(t('invalid_image'))
  }
}
const loadImage = (result) => {
  fileCopy.value = {
    encode_file: result
  }
  fileVariables.file = originalImage
  uploadFile()
}
const uploadFile = () => {
  showChangePictureDialog.value = false
  emits('mainImageUploaded', fileVariables)
}

const removeFile = () => {
  showChangePictureDialog.value = false
  ElMessageBox.confirm('', t('are_you_sure_you_want_to_delete_the_image'), {
    confirmButtonText: t('delete'),
    cancelButtonText: t('cancel'),
    confirmButtonClass: 'el-button--primary',
    cancelButtonClass: 'el-button--secondary',
    customClass: 'custom-delete-box no-subtitle'
  })
    .then(async () => {
      fileCopy.value = {
        encode_file: ''
      }
      fileVariables.file = null
      emits('mainImageUploaded', fileVariables)
    })
    .catch(() => {})
}
</script>

<style scoped lang="scss">
.add-avatar {
  .avatar-container {
    @media (min-width: 1100px) {
      height: 124px;
    }
    @media (max-width: 1100px) {
      height: 72px;
    }
    .avatar-image {
      display: inline-block;
      margin-right: 32px;
      @media (min-width: 1100px) {
        height: 124px;
        width: 124px;
      }
      @media (max-width: 1100px) {
        height: 72px;
        width: 72px;
      }
      vertical-align: top;
      .profile-img {
        @media (min-width: 1100px) {
          height: 124px;
          width: 124px;
        }
        @media (max-width: 1100px) {
          height: 72px;
          width: 72px;
        }
        border-radius: 8px;
        background-color: var(--background-tab);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }
    .avatar-uploader {
      height: 124px;
      width: calc(100% - 124px - 32px);
      @media (min-width: 1100px) {
        max-width: 367px;
      }
      display: inline-block;
      :deep(.el-upload) {
        .el-upload-dragger {
          border-radius: 8px;
          border: 1px solid var(--brand-off-white);
          background: var(--brand-white);
          @media (min-width: 1100px) {
            padding: 16px 24px;
          }
          height: 124px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .uploader-icon {
            border-radius: 28px;
            border: 6px solid var(--background-tab);
            background: var(--brand-off-white);
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (min-width: 1100px) {
              margin-bottom: 12px;
            }
          }
          .uploader-title {
            width: 239px;
            @media (min-width: 1100px) {
              max-width: 367px;
              margin-bottom: 4px;
            }
            .title1 {
              color: var(--brand-blue);
            }
            .title2 {
              color: var(--brand-off-black);
            }
          }
          .uploader-subtitle {
            color: var(--tins-dark-1);
          }
        }
      }
    }
    .avatar-new-uploader {
      display: inline-block;
      height: 124px;
      width: calc(100% - 124px - 32px);
      @media (min-width: 1100px) {
        max-width: 367px;
      }
      .button-remove {
        margin-right: 12px;
        height: 124px;
        display: inline-block;
        position: relative;
        top: 42px;
      }

      .button-upload {
        height: 124px;
        display: inline-block;
        position: relative;
        top: 42px;
      }
      @media (max-width: 1100px) {
        .button-upload {
          top: 25px;
        }
      }
    }
  }
}
</style>